<template>
  <div class="accessories-root-container">
    <div class="accessories-header-container">
      <span style="font-size: 23px;font-weight:bold">全部附件{{accessories.length}}个</span>
      <Icon @click="upload" type="ios-cloud-upload-outline" style="cursor: pointer;" :size="30" />
    </div>

    <accessories-list :dataSource="accessories" />
    <accessories-drawer ref="accessoriesDrawer" @add="add" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import AccessoriesList from "../../components/List/AccessoryList";
import AccessoriesDrawer from "../../components/Drawer/AccessoriesDrawer";
import moment from "moment";
export default {
  components: {
    "accessories-list": AccessoriesList,
    "accessories-drawer": AccessoriesDrawer,
  },
  data() {
    return {
      projectId: 0,
      accessories: [],
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.id;
    this.refresh();
  },
  methods: {
    ...mapActions({
      projectGetAccessoriesListAction: "projectGetAccessoriesList",
      projectAddAccessoriesAction: "projectAddAccessories",
    }),
    upload() {
      this.$refs.accessoriesDrawer.open();
    },
    refresh() {
      this.projectGetAccessoriesListAction({
        employer_id: localStorage.getItem("employer_id"),
        project_id: this.projectId,
      })
        .then((res) => {
          for (let item of res) {
            item.create_time = moment(item.create_time).format(
              "YYYY/MM/DD HH:mm"
            );
          }
          this.accessories = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    add(acc) {
      let params = Object.assign(acc, {
        employer_id: localStorage.getItem("employer_id"),
        project_id: this.projectId,
      });
      this.projectAddAccessoriesAction(params)
        .then(() => {
          this.refresh();
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.accessories-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 50px;
  overflow: hidden;
}
.accessories-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>